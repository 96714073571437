<template>
  <div>
    <BModal
      id="modal-forgot-pin"
      ref="modal-forgot-pin"
      no-close-on-backdrop
      hide-footer
      modal-class="modal-primary"
      centered
      hide-header
    >
      <BRow class="justify-end">
        <img
          src="@/assets/images/svg/close-circle.svg"
          alt="Komerce"
          style="margin-right: 10px"
          class="cursor-pointer"
          @click="$bvModal.hide('modal-forgot-pin')"
        >
      </BRow>
      <BCol class="d-flex justify-content-center mt-2">
        <h4 class="text-black font-semibold">
          Lupa PIN
        </h4>
      </BCol>

      <BCol class="d-flex justify-content-center mt-1 mb-1">
        <small class="text-center">
          <strong>Pilih metode untuk mengirim kode</strong>
        </small>
      </BCol>

      <BRow class="d-flex justify-content-center ml-1 pb-2">
        <BCol
          md="6"
          class="d-flex justify-content-center"
        >
          <div class="demo-inline-spacing">
            <BButton
              v-BModal="'modal-forgot-phone-pin'"
              block
              variant="primary"
              @click="forgotPinByPhone"
            >
              SMS
            </BButton>
          </div>
        </BCol>
      </BRow>
    </BModal>
    <ModalForgotPinByPhone
      :forgot-pin-by-phone="forgotPinByPhone"
      :count-otp="countOtp"
      :expired-otp="expiredOtp"
    />
  </div>
</template>

<script>
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import provider from '@/provider/provider'
import { SEND_OTP_EMAIL, SMS_OTP } from '@/provider/url'
import {
  BCol, BButton, BModal, BRow, VBModal,
} from 'bootstrap-vue'
import ModalForgotPinByPhone from './ModalForgotPinByPhone.vue'

export default {
  name: 'ModalForgotPin',
  components: {
    ModalForgotPinByPhone,
    BCol,
    BButton,
    BModal,
    BRow,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      phoneNumber: this?.$store?.state?.auth?.userData?.mitras?.phone_number,
      visibilityOTP: 'password',
      disableBtn: false,
      counting: false,
      countOtp: 0,
      expiredOtp: 0,
    }
  },
  methods: {
    async forgotPinByPhone() {
      this.loadingSubmit = true
      this.disableBtn = true
      const datas = new FormData()
      const dataBody = [
        {
          name: 'phone_number',
          value: this.phoneNumber,
        },
        {
          name: 'session',
          value: 'pin',
        },
      ]

      dataBody.map(item => datas.append(item.name, item.value))
      await provider
        .insert({
          url: SMS_OTP,
          body: datas,
        })
        .then(res => {
          const { data } = res
          this.countOtp = data.expired_at
          this.expiredOtp = data.check_request_otp
          this.loadingSubmit = false
          this.countDownTimerOtp()
          this.disableBtn = true
        })
        .catch(() => {
          this.loadingSubmit = false
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'gagal',
                icon: 'AlertCircleIcon',
                text: 'Gagal kirim OTP, silahkan coba lagi',
                variant: 'danger',
              },
            },
            2000,
          )
          this.disableBtn = true
        })
    },
    async forgotPinByEmail() {
      this.loadingSubmit = true
      await provider.list(SEND_OTP_EMAIL)
        .then(() => {
          this.loadingSubmit = false
        })
        .catch(() => {
          this.loadingSubmit = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'gagal',
              icon: 'AlertCircleIcon',
              text: 'Gagal kirim OTP, silahkan coba lagi',
              variant: 'danger',
            },
          }, 2000)
          this.loadingSubmit = true
        })
    },
    countDownTimerOtp() {
      if (this.countOtp > 0) {
        setTimeout(() => {
          this.countOtp -= 1
          this.countDownTimerOtp()
        }, 1000)
      }
    },
  },
}
</script>
