<template>
  <div>
    <BModal
      id="modal-forgot-phone-pin"
      ref="modal-forgot-phone-pin"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      modal-class="modal-primary"
      centered
      hide-header
    >
      <BRow class="justify-end">
        <img
          src="@/assets/images/svg/close-circle.svg"
          alt="Komerce"
          style="margin-right: 10px"
          class="cursor-pointer"
          @click="closeModal"
        >
      </BRow>
      <BCol class="d-flex justify-content-center mt-2">
        <h4 class="text-black font-semibold">
          Lupa PIN
        </h4>
      </BCol>

      <BCol class="d-flex justify-content-center mt-1">
        <small class="text-center">
          <strong>Masukan kode verifikasi (OTP) yang dikirimkan ke nomor
            0{{ phoneNumber }}</strong>
        </small>
      </BCol>

      <BCol class="d-flex justify-content-center mt-2 mb-1">
        <VueOtpInput
          ref="otpInput"
          class="otpInputPhone"
          input-classes="otp-input"
          :input-type="visibilityOTP"
          separator="-"
          :num-inputs="6"
          :should-auto-focus="true"
          :is-input-num="true"
          @on-change="handleOnChange"
        />
      </BCol>

      <BCol class="d-flex justify-content-center">
        <BButton
          variant="flat-primary"
          class="btn-icon"
          @click="toggleVisibilityOTP"
        >
          <div>
            Tampilkan / Sembunyikan
          </div>
        </BButton>
      </BCol>

      <BCol class="d-flex justify-content-center mt-1 text-center">
        <span v-if="countOtp > 0">
          Mohon tunggu {{ countOtp }}. detik untuk mengirim ulang.
        </span>

        <BButton
          v-else
          variant="flat-primary"
          size="sm"
          class="btn-icon"
          @click="forgotPinByPhone"
        >
          Kirim Ulang
        </BButton>
      </BCol>

      <BCol class="d-flex justify-content-center mt-1 pb-2">
        <BButton
          variant="primary"
          :disabled="otp === '' || otp.length < 6"
          @click="handleChangePinByPhone"
        >
          Konfirmasi
        </BButton>
      </BCol>
    </BModal>

    <BModal
      id="forgot-create-new-pin-phone"
      ref="forgot-create-new-pin-phone"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      modal-class="modal-primary"
      centered
      hide-header
    >
      <BRow class="justify-end">
        <img
          src="@/assets/images/svg/close-circle.svg"
          alt="Komerce"
          style="margin-right: 10px"
          class="cursor-pointer"
          @click="$bvModal.hide('forgot-create-new-pin-phone')"
        >
      </BRow>
      <BCol class="d-flex justify-content-center mt-2">
        <h4 class="text-black font-semibold">
          Lupa PIN
        </h4>
      </BCol>
      <BCol class="d-flex justify-content-center mt-1">
        <small class="text-center">
          <strong>Masukan PIN Baru</strong>
        </small>
      </BCol>

      <BCol class="d-flex justify-content-center mt-2 mb-1">
        <CodeInput
          :style="isVisibility ?'-webkit-text-security: disc' : '-webkit-text-security: none'"
          :loading="false"
          class="input"
          :type="visibilityPin"
          @change="onChange"
        />
      </BCol>

      <BCol class="d-flex justify-content-center">
        <BButton
          variant="flat-primary"
          class="btn-icon"
          @click="toggleVisibilityPin"
        >
          <div>
            Tampilkan / Sembunyikan
          </div>
        </BButton>
      </BCol>

      <BCol class="d-flex justify-content-center mt-1 pb-2">
        <BButton
          v-BModal="'forgot-confirm-new-pin-phone'"
          variant="primary"
          :disabled="dataPin === '' || dataPin.length < 6"
        >
          Ganti Sekarang
        </BButton>
      </BCol>
    </BModal>

    <BModal
      id="forgot-confirm-new-pin-phone"
      ref="forgot-confirm-new-pin-phone"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      modal-class="modal-primary"
      centered
      hide-header
    >
      <BRow class="justify-end">
        <img
          src="@/assets/images/svg/close-circle.svg"
          alt="Komerce"
          style="margin-right: 10px"
          class="cursor-pointer"
          @click="$bvModal.hide('forgot-confirm-new-pin-phone')"
        >
      </BRow>
      <BCol class="d-flex justify-content-center mt-2">
        <h4 class="text-black font-semibold">
          Lupa PIN
        </h4>
      </BCol>
      <BCol class="d-flex justify-content-center mt-1">
        <small class="text-center">
          <strong>Masukan Ulang PIN</strong>
        </small>
      </BCol>

      <BCol class="d-flex justify-content-center mt-2 mb-1">
        <CodeInput
          :style="isVisibility ?'-webkit-text-security: disc' : '-webkit-text-security: none'"
          :loading="false"
          class="input"
          :type="visibilityPin"
          @change="changePinConfirm"
        />
      </BCol>

      <BCol class="d-flex justify-content-center">
        <BButton
          variant="flat-primary"
          class="btn-icon"
          @click="toggleVisibilityPin"
        >
          <div>
            Tampilkan / Sembunyikan
          </div>
        </BButton>
      </BCol>

      <BCol class="d-flex justify-content-center mt-1 pb-2">
        <BButton
          variant="primary"
          :disabled="pinConfirm === '' || pinConfirm.length < 6"
          @click="onFinishChangePin"
        >
          <BSpinner
            v-if="loadingSubmit"
            small
            variant="light"
          />
          Konfirmasi PIN
        </BButton>
      </BCol>
    </BModal>

    <BModal
      id="modal-success-create-pin"
      ref="modal-success-create-pin"
      hide-footer
      hide-header
      modal-class="modal-dark"
      centered
      no-close-on-backdrop
      no-close-on-esc
    >
      <ModalSuccessCreatePin :changepin="messageChangePin" />
    </BModal>
  </div>
</template>

<script>
import VueOtpInput from '@bachdgvn/vue-otp-input'
import CodeInput from 'vue-verification-code-input'
import { UPDATE_PIN, OTP_VERIFICATION } from '@/provider/url'
import provider from '@/provider/provider'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import {
  BCol, BButton, BModal, BSpinner,
} from 'bootstrap-vue'
import ModalSuccessCreatePin from './ModalSuccessCreatePin.vue'

export default {
  name: 'ModalForgotPinByEmail',
  components: {
    VueOtpInput,
    CodeInput,
    BModal,
    BSpinner,
    BButton,
    BCol,
    ModalSuccessCreatePin,
  },
  props: {
    forgotPinByPhone: {
      type: Function,
      required: true,
    },
    countOtp: {
      type: Number,
      default: () => 0,
    },
    expiredOtp: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      loadingSubmit: false,
      phoneNumber: this?.$store?.state?.auth?.userData?.mitras?.phone_number,
      userId: this?.$store?.state?.auth?.userData?.id,
      visibilityOTP: 'password',
      otp: '',
      visibilityPin: 'number',
      isVisibility: true,
      errorMatchesPin: '',
      messageChangePin: 'PIN Kamu Berhasil Di ganti',
      dataPin: '',
      pinConfirm: '',
    }
  },
  methods: {
    async handleChangePinByPhone() {
      this.loadingSubmit = true
      const data = new FormData()
      const dataBody = [
        {
          name: 'otp',
          value: this.otp,
        },
        {
          name: 'session',
          value: 'pin',
        },
      ]

      dataBody.map(item => data.append(item.name, item.value))
      await provider
        .insert({
          url: OTP_VERIFICATION,
          body: data,
        })
        .then(() => {
          this.loadingSubmit = false
          this.$refs['modal-forgot-phone-pin'].hide()
          this.$refs['forgot-create-new-pin-phone'].show()
        })
        .catch(() => {
          this.loadingSubmit = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Gagal',
              icon: 'AlertCircleIcon',
              text: 'OTP yang anda masukan salah',
              variant: 'danger',
            },
          })
        })
    },
    async onFinishChangePin() {
      const payload = {
        user_id: this.userId,
      }
      this.loadingSubmit = true
      await this.$http.post('/user/get-profile', payload)
        .then(async res => {
          const { data } = res.data
          const dataBody = {
            user_id: this.userId,
            pin: this.dataPin,
            pin_last: data.pin,
            pin_confirm: this.pinConfirm,
          }
          await provider.update(UPDATE_PIN, dataBody)
            .then(() => {
              this.loadingSubmit = false
              this.$refs['modal-success-create-pin'].show()
              this.$refs['forgot-create-new-pin-phone'].hide()
              this.$refs['forgot-confirm-new-pin-phone'].hide()
              this.$refs['modal-forgot-phone-pin'].hide()
              this.$bvModal.hide('modal-forgot-pin')
            })
            .catch(() => {
              this.$toast(
                {
                  component: ToastificationContentVue,
                  props: {
                    title: 'Gagal',
                    icon: 'AlertCircleIcon',
                    text: 'PIN yang anda masukan tidak sama dengan pin yang sebelum nya',
                    variant: 'danger',
                  },
                },
                2000,
              )
              this.loadingSubmit = false
            })
        })
    },
    handleOnChange(value) {
      this.otp = value
    },
    toggleVisibilityOTP() {
      if (this.visibilityOTP === 'password') {
        this.visibilityOTP = 'number'
      } else {
        this.visibilityOTP = 'password'
      }
    },
    onChange(value) {
      this.dataPin = value
    },
    changePinConfirm(value) {
      this.pinConfirm = value
    },
    toggleVisibilityPin() {
      this.isVisibility = !this.isVisibility
    },
    closeModal() {
      this.$bvModal.hide('modal-forgot-pin')
      this.$refs['modal-forgot-phone-pin'].hide()
    },
  },
}
</script>
