<template>
  <div>
    <BModal
      id="modal-change-pin"
      ref="modal-change-pin"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      modal-class="modal-primary"
      centered
      hide-header
    >
      <BRow class="justify-end">
        <img
          src="@/assets/images/svg/close-circle.svg"
          alt="Komerce"
          style="margin-right: 10px"
          class="cursor-pointer"
          @click="$bvModal.hide('modal-change-pin')"
        >
      </BRow>
      <BCol class="d-flex justify-content-center mt-2">
        <h4 class="text-black font-semibold">
          Ganti PIN
        </h4>
      </BCol>

      <BCol class="d-flex justify-content-center mt-1">
        <small class="text-center">
          <strong>Masukan PIN Lama Kamu</strong>
        </small>
      </BCol>

      <BCol class="d-flex justify-content-center mt-2 mb-1">
        <CodeInput
          :loading="false"
          :style="isVisibility ?'-webkit-text-security: disc' : '-webkit-text-security: none'"
          class="input"
          :type="visibilityPin"
          @change="changePinLast"
        />
      </BCol>

      <BCol class="d-flex justify-content-center">
        <BButton
          variant="flat-primary"
          class="btn-icon"
          @click="toggleVisibilityPin"
        >
          <div>
            Tampilkan / Sembunyikan
          </div>
        </BButton>
      </BCol>

      <BCol class="d-flex justify-content-center mt-1 pb-2">
        <BButton
          variant="primary"
          :disabled="pinLast === null || pinLast.length < 6"
          @click="confirmPin"
        >
          <BSpinner
            v-if="loadingSubmit"
            variant="light"
            small
          />
          Konfirmasi
        </BButton>
      </BCol>
    </BModal>

    <BModal
      id="modal-new-pin"
      ref="modal-new-pin"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      modal-class="modal-primary"
      centered
      hide-header
    >
      <BRow class="justify-end">
        <img
          src="@/assets/images/svg/close-circle.svg"
          alt="Komerce"
          style="margin-right: 10px"
          class="cursor-pointer"
          @click="$bvModal.hide('modal-new-pin')"
        >
      </BRow>
      <BCol class="d-flex justify-content-center mt-2">
        <h4 class="text-black font-semibold">
          Ganti PIN
        </h4>
      </BCol>

      <BCol class="d-flex justify-content-center mt-1">
        <small class="text-center">
          <strong>Masukan PIN Baru Kamu</strong>
        </small>
      </BCol>

      <BCol class="d-flex justify-content-center mt-2 mb-1">
        <CodeInput
          :loading="false"
          :style="isVisibility ?'-webkit-text-security: disc' : '-webkit-text-security: none'"
          :type="visibilityPin"
          @change="onChange"
        />
      </BCol>

      <BCol class="d-flex justify-content-center">
        <BButton
          variant="flat-primary"
          class="btn-icon"
          @click="toggleVisibilityPin"
        >
          <div>
            Tampilkan / Sembunyikan
          </div>
        </BButton>
      </BCol>

      <BCol class="d-flex justify-content-center mt-1 pb-2">
        <BButton
          v-BModal="'modal-confirm-new-pin'"
          :disabled="dataPin === '' || dataPin.length < 6"
          variant="primary"
        >
          <BSpinner
            v-if="loadingSubmit"
            variant="light"
            small
          />
          Ganti Sekarang
        </BButton>
      </BCol>
    </BModal>

    <BModal
      id="modal-confirm-new-pin"
      ref="modal-confirm-new-pin"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      centered
      hide-header
    >
      <BRow class="justify-end">
        <img
          src="@/assets/images/svg/close-circle.svg"
          alt="Komerce"
          style="margin-right: 10px"
          class="cursor-pointer"
          @click="$bvModal.hide('modal-confirm-new-pin')"
        >
      </BRow>
      <BCol class="d-flex justify-content-center mt-2">
        <h4 class="text-black font-semibold">
          Ganti PIN
        </h4>
      </BCol>

      <BCol class="d-flex justify-content-center mt-1">
        <small class="text-center">
          <strong>Masukan Ulang PIN Baru Kamu</strong>
        </small>
      </BCol>

      <BCol class="d-flex justify-content-center mt-2 mb-1">
        <CodeInput
          :loading="false"
          :style="isVisibility ?'-webkit-text-security: disc' : '-webkit-text-security: none'"
          class="input"
          :type="visibilityPin"
          @change="changePinConfirm"
        />
      </BCol>

      <BCol class="d-flex justify-content-center">
        <BButton
          variant="flat-primary"
          class="btn-icon"
          @click="toggleVisibilityPin"
        >
          <div>
            Tampilkan / Sembunyikan
          </div>
        </BButton>
      </BCol>

      <BCol class="d-flex justify-content-center mt-1 pb-2">
        <BButton
          variant="primary"
          :disabled="pinConfirm === null || pinConfirm.length < 6"
          @click="onFinishChangePin"
        >
          <BSpinner
            v-if="loadingSubmit"
            small
            variant="light"
          />
          Konfirmasi PIN
        </BButton>
      </BCol>
    </BModal>

    <BModal
      id="modal-success-create-pin"
      ref="modal-success-create-pin"
      hide-footer
      hide-header
      modal-class="modal-dark"
      centered
      no-close-on-backdrop
      no-close-on-esc
    >
      <ModalSuccessCreatePin :changepin="messageChangePin" />
    </BModal>
  </div>
</template>

<script>
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import CodeInput from 'vue-verification-code-input'
import provider from '@/provider/provider'
import { UPDATE_PIN, CONFIRM_PIN } from '@/provider/url'
import {
  BCol, BButton, BSpinner, BModal,
} from 'bootstrap-vue'
import ModalSuccessCreatePin from './ModalSuccessCreatePin.vue'

export default {
  name: 'ModalChangePin',
  components: {
    CodeInput,
    ModalSuccessCreatePin,
    BCol,
    BButton,
    BSpinner,
    BModal,

  },
  data() {
    return {
      visibilityPin: 'number',
      isVisibility: true,
      loadingSubmit: false,
      userId: this?.$store?.state?.auth?.userData?.id,
      dataPin: '',
      messageChangePin: 'PIN Kamu Berhasil Di ganti',
      pinLast: '',
      pinConfirm: '',
    }
  },
  methods: {
    async confirmPin() {
      this.loadingSubmit = true
      await provider
        .single(`${CONFIRM_PIN}?user_id=${this.userId}&pin=${this.pinLast}`)
        .then(response => {
          const { data } = response
          if (data.is_match === true) {
            this.loadingSubmit = false
            this.$refs['modal-new-pin'].show()
            this.$refs['modal-change-pin'].hide()
          } else {
            this.loadingSubmit = false
            this.$toast(
              {
                component: ToastificationContentVue,
                props: {
                  title: 'Gagal',
                  icon: 'AlertCircleIcon',
                  text: 'PIN yang anda masukkan salah',
                  variant: 'danger',
                },
              },
              2000,
            )
          }
        })
        .catch(() => {
          this.loadingSubmit = false
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: 'Gagal buat ganti pin, silahkan coba lagi',
                variant: 'danger',
              },
            },
            2000,
          )
        })
    },
    async onFinishChangePin() {
      this.loadingSubmit = true
      const payload = {
        user_id: this.userId,
      }
      await this.$http.post('/user/get-profile', payload)
        .then(async res => {
          const { data } = res.data
          const dataBody = {
            user_id: this.userId,
            pin: this.dataPin,
            pin_last: data.pin,
            pin_confirm: this.pinConfirm,
          }
          await provider.update(UPDATE_PIN, dataBody)
            .then(() => {
              this.loadingSubmit = false
              this.$refs['modal-confirm-new-pin'].hide()
              this.$refs['modal-success-create-pin'].show()
              this.$refs['modal-new-pin'].hide()
            })
            .catch(() => {
              this.$toast(
                {
                  component: ToastificationContentVue,
                  props: {
                    title: 'Gagal',
                    icon: 'AlertCircleIcon',
                    text: 'PIN yang anda masukan tidak sama dengan pin yang sebelum nya',
                    variant: 'danger',
                  },
                },
                2000,
              )
              this.loadingSubmit = false
            })
        })
    },
    changePinLast(value) {
      this.pinLast = value
    },
    changePinConfirm(value) {
      this.pinConfirm = value
    },
    onChange(value) {
      this.dataPin = value
    },
    toggleVisibilityPin() {
      this.isVisibility = !this.isVisibility
    },
  },
}
</script>
