<template>
  <BCard>
    <h4 class="text-black font-semibold">
      PIN
    </h4>
    <BRow class="justify-center">
      <BCol
        lg="6"
        class="text-center"
      >
        <b-img
          variant="light-primary"
          square
          class="m-auto w-14"
          src="https://storage.googleapis.com/komerce/core/icon-on-page-pin.png"
        />
        <h5 class="text-black mt-2">
          Pastikan PIN kamu aman karena digunakan untuk menarik saldo dan
          mengatur rekening bank
        </h5>
        <BRow class="justify-center my-2">
          <BButton
            v-b-modal="'modal-forgot-pin'"
            variant="outline-primary"
            class="mr-1"
          >
            Lupa PIN
          </BButton>
          <BButton
            v-b-modal="'modal-change-pin'"
            variant="outline-primary"
          >
            Ganti PIN
          </BButton>
        </BRow>
      </BCol>
    </BRow>

    <ModalCreatePin />
    <ModalChangePin />
    <ModalForgotPin />
  </BCard>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BImg,
  VBModal,
} from 'bootstrap-vue'
import ModalCreatePin from './components/ModalCreatePin.vue'
import ModalChangePin from './components/ModalChangePin.vue'
import ModalForgotPin from './components/ModalForgotPin.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BImg,
    ModalCreatePin,
    ModalChangePin,
    ModalForgotPin,
  },
  directives: {
    'b-modal': VBModal,
  },
}
</script>

<style>
[dir] .otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}
[dir] .otp-input::-webkit-inner-spin-button,
[dir] .otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
