<template>
  <div>
    <BModal
      ref="create-pin"
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      hide-header
      modal-class="modal-primary"
      centered
      title="Primary Modal"
    >
      <BCol class="d-flex justify-content-center mt-2">
        <h4 class="text-black font-semibold">
          Buat PIN
        </h4>
      </BCol>

      <BCol class="d-flex justify-content-center mt-1">
        <small>
          <strong>Amankan uang kamu di Kompack menggunakan PIN</strong>
        </small>
      </BCol>

      <BCol class="d-flex justify-content-center mt-2 mb-1">
        <CodeInput
          :style="isVisibility ?'-webkit-text-security: disc' : '-webkit-text-security: none'"
          :loading="false"
          class="input"
          :type="visibilityPin"
          @change="onChange"
        />
      </BCol>

      <BCol class="d-flex justify-content-center">
        <BButton
          variant="flat-primary"
          class="btn-icon"
          @click="toggleVisibilityPin()"
        >
          <div>
            Tampilkan / Sembunyikan
          </div>
        </BButton>
      </BCol>

      <BCol class="d-flex justify-content-center mt-1 pb-2">
        <BButton
          v-BModal.modal-confirm-create-new-pin
          variant="primary"
          :disabled="pin === '' || pin.length < 6"
        >
          Buat PIN
        </BButton>
      </BCol>

      <BCol class="d-flex justify-content-center">
        <BButton
          variant="flat-primary"
          class="btn-icon"
          @click="closeModal"
        >
          <div class="font-bold">
            Nanti
          </div>
        </BButton>
      </BCol>
    </BModal>

    <BModal
      id="modal-confirm-create-new-pin"
      ref="modal-confirm-create-new-pin"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      hide-header
      modal-class="modal-primary"
      centered
    >
      <BRow class="justify-end">
        <img
          src="@/assets/images/svg/close-circle.svg"
          alt="Komerce"
          style="margin-right: 10px"
          class="cursor-pointer"
          @click="$bvModal.hide('modal-confirm-create-new-pin')"
        >
      </BRow>
      <BCol class="d-flex justify-content-center mt-2">
        <h4 class="text-black font-semibold">
          Konfirmasi PIN
        </h4>
      </BCol>

      <BCol class="d-flex justify-content-center mt-1">
        <small class="text-center">
          <strong>Masukan Ulang PIN</strong>
        </small>
      </BCol>

      <BCol class="d-flex justify-content-center mt-2 mb-1">
        <CodeInput
          :style="isVisibility ?'-webkit-text-security: disc' : '-webkit-text-security: none'"
          :loading="false"
          class="input"
          :type="visibilityPin"
          @change="onChangeConfirm"
        />
      </BCol>

      <BCol class="d-flex justify-content-center">
        <BButton
          variant="flat-primary"
          class="btn-icon"
          @click="toggleVisibilityPin()"
        >
          <div>
            Tampilkan / Sembunyikan
          </div>
        </BButton>
      </BCol>

      <BCol class="d-flex justify-content-center mt-1 pb-2">
        <BButton
          variant="primary"
          :disabled="pinConfirm === '' || pinConfirm.length < 6"
          @click="confirmCreatePin"
        >
          <BSpinner
            v-if="loadingSubmit"
            variant="light"
            small
          />
          Konfirmasi
        </BButton>
      </BCol>
    </BModal>

    <BModal
      id="modal-success-create-pin"
      ref="modal-success-create-pin"
      hide-footer
      hide-header
      modal-class="modal-dark"
      centered
      no-close-on-backdrop
      no-close-on-esc
    >
      <ModalSuccessCreatePin :createpin="messageCreatePin" />
    </BModal>
  </div>
</template>

<script>
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import provider from '@/provider/provider'
import { SETTING_PIN, STORE_PIN } from '@/provider/url'
import { kompackAxiosIns } from '@/libs/axios'
import CodeInput from 'vue-verification-code-input'
import {
  BModal, BCol, BButton, BSpinner,
} from 'bootstrap-vue'
import ModalSuccessCreatePin from './ModalSuccessCreatePin.vue'

export default {
  name: 'ModalCreateConfirmationPin',
  components: {
    ModalSuccessCreatePin,
    CodeInput,
    BModal,
    BCol,
    BButton,
    BSpinner,
  },
  data() {
    return {
      visibilityPin: 'number',
      isVisibility: true,
      errorConfirmPin: '',
      loadingSubmit: false,
      userId: this?.$store?.state?.auth?.userData?.id,
      pin: '',
      messageCreatePin: 'PIN Kamu Berhasil Dibuat',
      showHidePin: '',
      pinConfirm: '',
    }
  },
  mounted() {
    this.getDataCheckPin()
  },
  methods: {
    async getDataCheckPin() {
      await kompackAxiosIns
        .get(`${SETTING_PIN}?user_id=${this.userId}`)
        .then(res => {
          const { data } = res
          if (data.status === 'success') {
            this.$refs['create-pin'].hide()
          } else {
            this.$refs['create-pin'].show()
          }
        })
        .catch(() => {
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Gagal',
                icon: 'AlertIconIcon',
                text: 'Gagal cek pin, silahkan refresh page',
                variant: 'danger',
              },
            },
            2000,
          )
        })
    },
    async confirmCreatePin() {
      this.loadingSubmit = true
      const data = new FormData()
      const dataBody = [
        {
          name: 'user_id',
          value: this.userId,
        },
        {
          name: 'pin',
          value: this.pin,
        },
        {
          name: 'pin_confirm',
          value: this.pinConfirm,
        },
      ]
      dataBody.map(item => data.append(item.name, item.value))

      await provider
        .insert({
          url: STORE_PIN,
          body: data,
        })
        .then(() => {
          this.loadingSubmit = false
          this.$refs['create-pin'].hide()
          this.$refs['modal-confirm-create-new-pin'].hide()
          this.$refs['modal-success-create-pin'].show()
        })
        .catch(() => {
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: 'PIN yang anda masukan tidak sama dengan pin yang sebelum nya',
                variant: 'danger',
              },
            },
            2000,
          )
          this.loadingSubmit = false
        })
    },
    onChange(value) {
      this.pin = value
    },
    onChangeConfirm(value) {
      this.pinConfirm = value
    },
    toggleVisibilityPin() {
      this.isVisibility = !this.isVisibility
    },
    closeModal() {
      this.$refs['create-pin'].hide()
      this.$router.push('/profile')
    },
  },
}
</script>
